$('input[name="datesearch"]').datepicker({
    format: 'yyyy/mm/dd',
});

$(function () {
    $('input[name="timesearch"]').timepicker({
        timeFormat: 'HH:mm～',
        interval: 30,
        scrollbar: true,
        maxHour: 19,
        minHour: 7,
        dynamic: false,
        zIndex: 100
    });
});
getStopStation ()

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const stopStationId = urlParams.get('stop_station');

const btnSearch = $('#btnSearch');
const dateSearch = $('#datesearch');
const timeSearch = $('#timesearch');
const startStation = $('select[name="start_station"]');
const stopStation = $('select[name="stop_station"]');
const adult = $('#adult');
const child = $('#child');

btnSearch.on('click', function () {
    sessionStorage.setItem('dateSearch', dateSearch.val());
    sessionStorage.setItem('timeSearch', timeSearch.val());
    sessionStorage.setItem('startStation', startStation.find(":selected").val());
    sessionStorage.setItem('stopStation', stopStation.find(":selected").val());
    sessionStorage.setItem('adult', adult.val());
    sessionStorage.setItem('child', child.val());
});

if(!dateSearch.val()) {
    dateSearch.val(sessionStorage.getItem('dateSearch'));
}
if(!timeSearch.val()) {

    timeSearch.val(sessionStorage.getItem('timeSearch'));
}
if(!adult.val()) {
    adult.val(sessionStorage.getItem('adult'));

}
if(!child.val()) {
    child.val(sessionStorage.getItem('child'));
}

if(!startStation.find(":selected").val()) {
    getStopStation();
    startStation.val(sessionStorage.getItem('startStation')).change();
}
if(sessionStorage.getItem('stopStation')) {
    getStopStation();
    stopStation.val(sessionStorage.getItem('stopStation')).change();
}
if (location.pathname === '/' && Boolean(stopStation.find('option').length)) {
    sessionStorage.removeItem("dateSearch");
    sessionStorage.removeItem("timeSearch");
    sessionStorage.removeItem("adult");
    sessionStorage.removeItem("child");
    sessionStorage.removeItem("startStation");
    sessionStorage.removeItem("stopStation");
}

function getStopStation () {
    $.ajax({
        type: 'GET',
        url: $('#startStation').data('route'),
        data: {
            start_station: $('select[name="start_station"]').find('option:selected').val(),
        },
        dataType: 'json',
        success: data => {
            $('select[name="stop_station"]').empty();
            $('select[name="stop_station"]').append(`<option hidden="true" disabled selected>--${selectingMessage}--</option>`);
            for (const k in data) {
                const select = data[k].id === +stopStationId ? 'selected' : '';
                $('select[name="stop_station"]').append(`<option ${select} value=` + data[k].id + ' >' + data[k].title + (Boolean(data[k].is_clone_station) ? '（戻り）' : '' ) + '</option>');
            }
        }
    });
}
